// noinspection JSDeprecatedSymbols

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username: null,
    email: null,
    prices: null,
    distance: null,
    origin: null
  },
  mutations: {
    initUsername (state, username) {
      localStorage.username = username.name
      localStorage.email = username.email
      state.username = username.name
      state.email = username.email
    },
    initDistance(state, payload) {
      state.distance = payload.distance
      state.origin = payload.origin
    },
    initPrices (state, payload) {
      const prices = payload
      let products = []
      prices.clientPrices.forEach(el => {
        let productId = el.values.product[0].id
        let productPrice = el.values.price
        for (let i = 0; i < prices.globalPrices.length; i++) {
          if (prices.globalPrices[i].id == productId) {
            prices.globalPrices[i].values.default_price = productPrice
          }
        }
      })

      prices.globalPrices.forEach(el => {
        let key_name = el.values.name
        products.push({
          key: [key_name],
          value: el.values.default_price
        })
      })
      state.prices = products
    }
  },
  actions: {
    async checkUser ({ commit }, payload) {
      let userObj = await fetch(
        'https://europe-west3-tankkauspartio---extranet.cloudfunctions.net/app/api/auth/' + payload.username, {
          method: 'POST'
        })
        .then((response) => response.json())
        .then((data) => data.body)

      if (userObj.user.length) {
        commit('initUsername', userObj.user[0].values)
        return true
      } else {
        return false
      }
    },
    fetchPrices ({ commit }, payload) {
      fetch('https://europe-west3-tankkauspartio---extranet.cloudfunctions.net/app/api/getPrices/' + payload.username, {
        method: 'POST'
      })
        .then((response) => response.json())
        .then((data) => commit('initPrices', data.body))
    },
    getDistance({commit}, payload) {
      commit('initDistance', payload)
    }
  },
  modules: {
  }
})
