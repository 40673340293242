import Vue from 'vue'
import VueRouter from 'vue-router'
import mainView from '../views/main_view.vue'
import calculatorView from '@/views/calculator_view'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: mainView
  },
  {
    path: '/extranet',
    name: 'laskin',
    component: calculatorView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
