<template>
  <div class="st_calculator_page">
    <v-img :src="require('../assets/main_img.jpg')"/>
    <section class="st_inner">
      <h1 class="st_calculator_title">Extranet</h1>
      <v-btn
        type="submit"
        color="#4DACF8"
        class="white--text"
        elevation="0"
        @click="logout"
      >
        Kirjaudu ulos
      </v-btn>
    </section>
    <div class="st_extranet_text">
      <p>Tankit ja toiveet on tehty täytettäviksi. Polttoainetilauksen tekeminen on nyt entistä vaivattomampaa. Lisäksi voit ennakoida tulevien tilauksien kustannuksia näppärällä laskuritoiminnolla.</p>

      <p>Valttinamme on edelleen nopeus: sähköisen tilauksen myötä palvelumme vain nopeutuu. Toimituslupauksemme normaalitoimitukselle on alle 6 tuntia tankkiin! Pikatoimituksen toimitamme muutamassa tunnissa. Palvelemme työmaatasi pääkaupunkiseudulla, Keski-Uudellamaalla, Kanta-Hämeessä, Tampereen seudulla ja Turun seudulla.
      </p>
      <p>Tutustu laskuriin ja tee jatkossa tilaukset vaivattomasti täällä - 24/7.  </p>

      <p>Jos tarvitset apua palvelun käytössä, yhteyshenkilösi verkkotilausten osalta on</p>

      <p>myyntipäällikkö</p><br/>
        Jesse Teppo<br/>
        p. 044 241 2301<br/>
        <a href="mailto:jesse@tankkauspartio.fi">jesse@tankkauspartio.fi</a><br/>
    </div>
    <div class="st_extranet_areas">
        <h3>Toimimme <br/>päivittäin</h3>
        Pääkaupunkiseudulla, <br/>
        Keski-Uudellamaalla, <br/>
        Kanta-Hämeessä, <br/>
        Tampereen seudulla, <br/>
        Turun seudulla.
    </div>
    <div class="st_calculator_form" fill-height>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container>
            <h1>Laske kustannusarvio tankkaustarpeellesi</h1>
            <v-row justify="space-around" class="mt-5">
              <v-col
                cols="12"
                md="4"
              >
                <v-card-title class="st_nowrap_title_map">TANKKAUSKOHTEEN OSOITE</v-card-title>
                <GmapAutocomplete class="st_autocomplete_input" @place_changed="setPlace" />
                <div class="st_map_container">
                  <GmapMap :zoom="7" :center="center" class="st_map">
                    <DirectionsRenderer
                      travelMode="DRIVING"
                      :destination="endLocation"
                    />
                  </GmapMap>
                </div>
              </v-col>
              <div class="st_inputs_container">
                <div class="st_row_inputs">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-card-title class="st_nowrap_title">POLTTOÖLJYN MÄÄRÄ</v-card-title>
                    <v-text-field
                      background-color="#FFF"
                      v-model="liters"
                      :rules="amountRules"
                      outlined
                      suffix="litraa"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-card-title class="st_nowrap_title">ADBLUEN MÄÄRÄ</v-card-title>
                    <v-text-field
                      v-model="canisters"
                      background-color="#FFF"
                      :rules="amountRules"
                      outlined
                      suffix="kanisteria"
                      required
                    ></v-text-field>
                  </v-col>
                </div>
                <div class="st_form_btn_container">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-checkbox
                      v-model="filling"
                      label="Keskusvoitelujärjestelmän täyttö"
                      value="true"
                      class="st_checkbox"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="fast_delivery"
                      label="Pikatankkaus (1-3 tuntia tilauksen vahvistamisesta)"
                      value="true"
                      class="st_checkbox"
                    ></v-checkbox>
                  </v-col>
                  <v-btn
                    :disabled="!valid"
                    color="#4DACF8"
                    class="st_form_btn white--text"
                    @click="validate"
                  >
                    Laske hinta
                  </v-btn>
                </div>
              </div>
              <v-col
                cols="12"
                md="3"
              >
                <v-card-title style="justify-content: space-around !important;">HINTA-ARVIO</v-card-title>
                <div id="st_total_price" class="st_price-col">
                  <div v-if="totalPrice" id="st_result-price">
                    <span>{{ totalPrice }}</span> €
                  </div>
                  <div v-else id="st_result-price">
                    <span>0</span> €
                  </div>
                  <p class="st_price_text">+ alv. joka lisätään laskutuksen yhteydessä</p>
                  <v-btn :disabled="disable_order_btn" id="st_to-step-2" class="white--text" @click="submitOrder = true">Jatka tilaamaan</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-form
          ref="form2"
          v-model="valid2"
          lazy-validation
          v-if="submitOrder"
        >
          <v-container class="mt-5">
            <h1>Vahvista tilaus</h1>
            <v-row justify="space-around" class="mt-5">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  background-color="#FFF"
                  label="Toimitusosoitteen yhteyshenkilön puh.*"
                  v-model="contact_information"
                  persistent-hint
                  type="number"
                  :counter="10"
                  :rules="phoneRule"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  background-color="#FFF"
                  label="TYÖNUMERO / VIITE*"
                  v-model="ref"
                  :rules="inputRequiredRules"
                  persistent-hint
                  outlined
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  background-color="#FFF"
                  label="TANKATTAVAT KOHTEET*"
                  :rules="inputRequiredRules"
                  required
                  v-model="fueling_points"
                  persistent-hint
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  background-color="#FFF"
                  label="SÄILIÖIDEN LUKUMÄÄRÄ"
                  persistent-hint
                  v-model="tanks_amount"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  background-color="#FFF"
                  label="KONEIDEN LUKUMÄÄRÄ"
                  v-model="machines_amount"
                  persistent-hint
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col
                cols="12"
                md="6"
              >
                <v-textarea
                  background-color="#FFF"
                  outlined
                  :rules="inputRequiredRules"
                  name="input-7-4"
                  label="TÄYTTÖOHJEEN TARKENNUS(esim. tankattavien säiliöiden numerot, tankattavien koneiden tiedot)*"
                  v-model="filling_instructions"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-textarea
                  background-color="#FFF"
                  outlined
                  name="input-7-4"
                  label="LISÄTIETOJA KULJETTAJALLE"
                  v-model="driver_info"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col
                cols="12"
                md="6"
              >
                <div class="st_calculator_checkbox">
                  <v-checkbox
                    v-model="callBack"
                    label="Haluan, että minulle soitetaan ennen toimitusta tarkempien ohjeiden antamiseksi."
                    value="true"
                    class="st_checkbox"
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <p v-if="fast_delivery">
                  TOIMITUSAJANKOHTA*
                  Valitsit aiemmassa näkymässä toimitustavaksi pikatoimituksen. Toimitamme tilauksesi ilmoittamaasi osoitteeseen pikatilauksena kolmen (3) tunnin sisällä tilausvahvistuksen lähetyksestä sähköpostiisi.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-btn
                  :disabled="!valid2"
                  color="#4DACF8"
                  class="st_form_btn white--text"
                  @click="validate2"
                >
                  Lähetä tilaus
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >

              </v-col>
            </v-row>
          </v-container>
        </v-form>
    </div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="310"
    >
      <v-card class="justify-center">
        <v-card-title class="text-h6 justify-center">
          {{dialogTitle}}
        </v-card-title>
        <v-card-text class="justify-center">{{dialogMessage}}</v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="blue"
            text
            @click="submitDialog()"
          >
            Sulje
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import DirectionsRenderer from '@/plugins/DirectionsRenderer'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  components: {
    DirectionsRenderer
  },
  name: 'calculator_view',
  data: () => ({
    valid: true,
    valid2: true,
    fast_delivery: false,
    filling: false,
    tanks_amount: null,
    fueling_points: null,
    callBack: false,
    ref: null,
    contact_information: null,
    machines_amount: null,
    liters: null,
    filling_instructions: null,
    driver_info: null,
    canisters: null,
    disable_order_btn: true,
    submitOrder: false,
    dialog: false,
    dialogTitle: 'Tilaus on vastaanotettu',
    dialogMessage: 'Vahvistus lähetetty sähköpostille',
    orderItems: [],
    center: { lat: 60.192059, lng: 24.945831 },
    endLocation: null,
    totalPrice: null,
    amountRules: [
      v => !!v || 'Määrä on pakollinen kenttä',
      v => /^\d+$/.test(v) || 'Täytyy olla numero',
    ],
    inputRequiredRules: [
      v => !!v || 'Tämä kenttä on pakollinen'
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    phoneRule: [
      v => !!v || 'Puhelinnumero on pakollinen kenttä',
      v => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(v) || 'Puh. numero täytyy olla 0400123123 muodossa.',
    ],
    select: null,
    checkbox: false
  }),
  async mounted () {
    await this.fetchPrices()
  },
  computed: {
    ...mapState({
      username: state => state.username,
      prices: state => state.prices,
      distance: state => state.distance,
      origin: state => state.origin
    })
  },
  methods: {
    validate () {
      let validation = this.$refs.form.validate()
      if (validation && this.endLocation != null) {
        this.addMarker()
      }
    },
    validate2 () {
      let validation = this.$refs.form2.validate()
      if (validation) {
        this.sendOrder()
      }
    },
    logout() {
      this.$router.push('/')
      localStorage.removeItem('username')
      localStorage.removeItem('email')
    },
    async fetchPrices () {
      await this.$store.dispatch('fetchPrices', { username: localStorage.username })
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form2.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    setPlace (place) {
      this.endLocation = place
    },
    async addMarker () {
      let marker = null
      if (this.endLocation.formatted_adress) {
        marker = {
          lat: this.endLocation.geometry.location.lat(),
          lng: this.endLocation.geometry.location.lng()
        }
      } else {
        marker = this.endLocation
      }
      this.endLocation = marker
      this.center = marker
      this.calculatePrice()
    },
    calculatePrice() {
      this.orderItems = []
      let products = this.prices.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {})
      //products.P18 = this.filling
      //products.P2 = this.fast_delivery
      products.P6 = products.P6 * this.liters
      if (this.distance < 50.1) {
        products.P1 = (this.origin == 'Turku') ? 30 : 60
      } else {
        products.P1 = (this.origin == 'Turku') ? Math.ceil(30 + (this.distance * 2 * products.P4)) : Math.round(60 + (this.distance * 2 * products.P4))
      }

      if (this.liters >= 750) {
        products.P1 = 0;
        products.P25 = true;
      }

      if (this.canisters) {
        products.P16 = products.P16 * this.canisters
      }

      //checkboxes Pikatankkaus / Keskusvoitule täyttö
      products.P18 = this.filling ? products.P18 : 0
      products.P2 = this.fast_delivery ? products.P2 : 0

      this.totalPrice = Math.ceil(products.P6 + products.P16 + products.P2 + products.P18 + products.P1)
      this.disable_order_btn = false
      for (const [key, value] of Object.entries(products)) {
        this.orderItems.push({
          'code': key,
          'quantity': value
        })
      }
    },
    submitDialog() {
      this.dialogTitle = ''
      this.dialogMessage = ''
      localStorage.removeItem('username')
      localStorage.removeItem('email')
      window.open("https://www.tankkauspartio.fi/", "_self");
    },
    sendOrder() {
      let today = new Date()
      today = today.toISOString().split('T')[0]
      const call = this.callBack ? 'kyllä' : 'ei'
      let start, end;
      if (this.fast_delivery) {
        start = today + 'T07:00'
        end = today + 'T12:00'
      } else {
        start = today + 'T12:00'
        end = today + 'T19:00'
      }
      let order = {
          'description': 'Osoite: ' + this.endLocation.formatted_address + '\n' +
            'Kilometrien lkm: ' + this.distance + '\n' +
            'Säiliöiden lkm: ' + this.tanks_amount + '\n' +
            'Koneiden lkm: ' + this.machines_amount + '\n' +
            'Kohteet: ' + this.fueling_points + '\n' +
            'Täyttöohje: ' + this.filling_instructions + '\n' +
            'Lisätietoja: ' + this.driver_info + '\n' +
            'Kokonaishinta: ' + this.totalPrice + '\n' +
            'Soitetaanko: ' + call,
          'address': this.endLocation.formatted_address,
          'timeWindow': {
            'start': start,
            'end': end
          },
          'orderItems': this.orderItems
      }
      const email = localStorage.email
      const url = 'https://europe-west3-tankkauspartio---extranet.cloudfunctions.net/app/api/sendOrder/' + email
      axios.post(url, order, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          const data = response.data;
          this.reset()
          if (data.body.transportOrder) {
            this.dialogTitle = 'Kiitos! Tilaus on vastaanotettu'
            this.dialogMessage = 'Vahvistus lähetetty sähköpostille'
          } else {
            this.dialogTitle = 'Tilauksessa tapahtui virhe'
            this.dialogMessage = 'Ota yhteyttä asiakaspalveluun'
          }
        })
    }
  }
}
</script>

<style scoped>
.st_calculator_page {
  width: 100%;
}
.st_inner {
  position: relative;
  bottom: 200px;
}
.v-btn {
  border-radius: 0px !important;
  text-transform: none !important;
  font-size: 18px !important;
  padding: 24px 20px !important;
}
.st_calculator_title {
  color: #FFFFFF;
  line-height: 100%;
  font-size: 53px;
  margin: 40px 0;
}
.st_extranet_text {
  position: relative;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  bottom: 150px;
}
.st_extranet_areas {
  position: relative;
  bottom: 130px;
  width: 25%;
  margin: 0 auto;
  background-color: #DEF2FE;
  padding: 20px;
  color: #000;
}
.st_extranet_areas h3 {
  text-transform: uppercase;
  font-size: 28px;
  text-align: center;
  line-height: 100%;
  margin-bottom: 10px;
  color: #2C51A3;
}
.st_calculator_form {
  width: 100%;
  padding: 50px 0;
  background-color: #E2F1FD;
}
.st_autocomplete_input {
  width: 100%;
  float: left;
  padding: 14px;
  border: 1px solid;
  border-radius: 3px;
}
.st_map {
  margin-top: 100px;
  width: 100%;
  height: 400px
}
.st_checkbox {
  width: 100%;
}
.st_price-col {
  display: block;
  background: #fff;
  padding: 30px 60px 60px 60px;
  text-align: center;
}
#st_result-price {
  color: #66AAF2;
  font-size: 40px;
  margin: 20px 0;
  font-weight: bold;
}
.st_price_text {
  text-align: center;
  white-space: normal;
}
.st_nowrap_title_map {
  justify-content: left;
  white-space: nowrap;
}
.st_nowrap_title {
  white-space: nowrap;
  justify-content: center;
}
#st_to-step-2 {
  text-transform: uppercase;
  background: #294987;
}
.st_inputs_container {
  width: 30%
}
.st_row_inputs {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.st_form_btn_container {
  width: 100%;
}
.v-text-field--outlined .v-input__control {
  background-color: white !important;
}
input {
  background-color: white !important;
}

@media only screen and (max-width: 900px) {
  .st_inner {
    bottom: 100px;
  }
  .st_extranet_text {
    position: relative;
    width: 100%;
    bottom: 50px;
  }
  .st_extranet_areas {
    position: relative;
    bottom: 30px;
    width: 100%;
    background-color: #DEF2FE;
    padding: 20px;
    color: #000;
  }
  .st_inputs_container {
    width: 100%
  }
  .st_row_inputs {
    width: 100%;
    flex-wrap: wrap;
  }
}
</style>
