import { MapElementFactory } from 'vue2-google-maps'
import store from '@/store'

export default MapElementFactory({
  name: 'directionsRenderer',
  ctr () {
    return window.google.maps.DirectionsRenderer
  },
  events: [],
  mappedProps: {},
  props: {
    destination: { type: [Object, Array] },
    travelMode: { type: String }
  },
  async afterCreate (directionsRenderer) {

    const places = [
      {
        lat: 60.995302,
        lng: 24.456353
      },
      {
        lat: 61.495495,
        lng: 23.758622
      },
      {
        lat: 60.452043,
        lng: 22.268369
      },
      {
        lat: 60.340588,
        lng: 24.939079
      }
    ]

    this.$watch(
      () => [this.destination, this.travelMode],
      async () => {
        let {
          destination,
          travelMode
        } = this;
        if (!destination || !travelMode) return;
        const distanceMatrixService = new window.google.maps.DistanceMatrixService();
        distanceMatrixService.getDistanceMatrix(
          {
            origins: places,
            destinations: [destination.formatted_address],
            travelMode: 'DRIVING',
            avoidHighways: false,
            avoidTolls: false,
          }, callback);

        function callback(response, status) {
          if (status === 'OK') {
            let shortest = null;
            let nearestOffice = null;
            response.rows.forEach((el, index) => {
              let dist = el.elements[0].distance.value / 1000
              if(shortest == null ||  dist < shortest) {
                shortest = dist;
                nearestOffice = index;
              }
            })

            const directionsService = new window.google.maps.DirectionsService()
            const origin = places[nearestOffice]
            directionsService.route(
              {
                origin,
                destination: destination.formatted_address,
                travelMode
              },
              (response, status) => {
                if (status !== 'OK') return
                directionsRenderer.setDirections(response)
                store.dispatch('getDistance', {distance: shortest, origin: destination.vicinity})
              }
            )
          }
        }
      }
    );
  }
})
