<template>
  <v-container class="justify-center" fill-height>
    <v-flex class="justify-center xs-login-windows" xs12 sm8 md4>
      <v-container
        class="d-flex flex-column align-center"
        fluid
      >
        <v-toolbar-title class="st_title">KIRJAUDU EXTRANETIIN</v-toolbar-title>
      </v-container>
      <v-toolbar-title class="st_label">Asiakastunnus</v-toolbar-title>
      <v-form
        ref="form"
        @submit.prevent="submitHandler()"
      >
        <p v-if="this.error" class="red--text">{{errorText}}</p>
        <v-text-field
          color="#A5D2F7"
          outlined
          v-model.trim="username"
          required
        ></v-text-field>
        <v-card-actions class="justify-center">
          <v-btn
            type="submit"
            color="#4DACF8"
            class="white--text"
            elevation="0"
          >
            Kirjaudu
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-flex>
  </v-container>
</template>
<script>

export default {
  name: 'main_view',
  data: () => ({
    valid: true,
    username: null,
    errorText: '',
    dialog: false,
    error: false
  }),
  methods: {
    async submitHandler () {
      if (this.username) {
        const resp = await this.$store.dispatch('checkUser', { username: this.username })
        if (resp) {
          this.$router.push('/extranet')
        } else {
          this.errorMessage()
        }
      } else {
        this.errorText = 'Tunnus kenttä on pakollinen'
        this.error = true
        setTimeout(() => {
          this.error = false
          this.errorText = ''
        }, 5000)
      }
    },
    errorMessage () {
      this.error = true
      this.errorText = 'Tunnus ei löydetty tietokannasta'
      setTimeout(() => {
        this.error = false
        this.errorText = ''
      }, 5000)
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,700;1,300&family=Open+Sans:wght@400;700&display=swap');
.st_title {
  font-family: 'Open Sans Condensed', sans-serif;
  text-align: center;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 4.5vh;
  padding: 40px 0;
  color: #2C51A3;
}
.v-text-field--outlined {
  border-radius: 0px !important;
}
.v-btn {
  border-radius: 0px !important;
  text-transform: none !important;
  font-size: 18px !important;
  padding: 10px 20px !important;
}
.st_label {
  display: block;
  width: auto;
  line-height: 100%;
  margin-bottom: 10px;
  color: #1C3196;
  font-weight: bold;
  font-size: 16px;
}
</style>
